import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {ClientDashboardWrapper} from '../pages/client-dashboard/DashboardWrapper'
import {useAuth} from '../modules/auth'

const PrivateRoutes = () => {
  const RolePage = lazy(() => import('../modules/apps/role/RolePage'))
  const EmployeePage = lazy(() => import('../modules/apps/employee/EmployeePage'))
  const ReportPage = lazy(() => import('../modules/apps/report/ReportPage'))
  const BurnerTypePage = lazy(() => import('../modules/apps/burner-type/BurnerTypePage'))
  const BurnerPage = lazy(() => import('../modules/apps/burner/BurnerPage'))
  const VendorPage = lazy(() => import('../modules/apps/vendor/vendorPage'))
  const CrematoriumPage = lazy(() => import('../modules/apps/crematorium/CrematoriumPage'))
  const SlotPage = lazy(() => import('../modules/apps/slot/slotPage'))
  const BookingPage = lazy(() => import('../modules/apps/booking/BookingPage'))
  const MaintenancePage = lazy(() => import('../modules/apps/maintenance/maintenancePage'))
  const PaymentPage = lazy(() => import('../modules/apps/payment/paymentPage'))

  const RoleAccessPage = lazy(() => import('../modules/apps/role-access/RoleaccessPage'))

  const {currentUser} = useAuth()
  let too = ''
  if (currentUser?.employeeType === 1) {
     too = `/dashboard`
  }
  if (currentUser?.employeeType === 2) {
     too = `/crematorium-dashboard`
  }
  return (
    <Suspense fallback={<SuspendedView />}>
      <Routes>
        <Route element={<MasterLayout />}>
          {/* Redirect to Dashboard after success login/registartion */}
          <Route path='auth/*' element={<Navigate to={too} />} />

          {/* Pages */}
          <Route path='dashboard' element={<DashboardWrapper />} />
          <Route path='crematorium-dashboard' element={<ClientDashboardWrapper />} />
          <Route path='builder' element={<BuilderPageWrapper />} />
          <Route path='menu-test' element={<MenuTestPage />} />

          {/* Lazy Modules */}
          <Route
            path='roles/*'
            element={
              <SuspendedView>
                <RolePage />
              </SuspendedView>
            }
          />
          <Route
            path='employees/*'
            element={
              <SuspendedView>
                <EmployeePage />
              </SuspendedView>
            }
          />
         
          <Route
            path='page-access/*'
            element={
              <SuspendedView>
                <RoleAccessPage />
              </SuspendedView>
            }
          />
          <Route
            path='burner-types/*'
            element={
              <SuspendedView>
                <BurnerTypePage />
              </SuspendedView>
            }
          />
           <Route
            path='burners/*'
            element={
              <SuspendedView>
                <BurnerPage />
              </SuspendedView>
            }
          />
           <Route
            path='vendors/*'
            element={
              <SuspendedView>
                <VendorPage />
              </SuspendedView>
            }
          />
          <Route
            path='crematoriums/*'
            element={
              <SuspendedView>
                <CrematoriumPage />
              </SuspendedView>
            }
          />
           <Route
            path='slots/*'
            element={
              <SuspendedView>
                <SlotPage />
              </SuspendedView>
            }
          />
          <Route
            path='bookings/*'
            element={
              <SuspendedView>
                <BookingPage />
              </SuspendedView>
            }
          />
          <Route
            path='payments/*'
            element={
              <SuspendedView>
                <PaymentPage />
              </SuspendedView>
            }
          />
          <Route
            path='maintenance/*'
            element={
              <SuspendedView>
                <MaintenancePage />
              </SuspendedView>
            }
          />
          <Route
            path='reports/*'
            element={
              <SuspendedView>
                <ReportPage />
              </SuspendedView>
            }
          />
          {/* Page Not Found */}
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      </Routes>
    </Suspense>
  )
}

const SuspendedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
