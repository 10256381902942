/* eslint-disable react/jsx-no-target-blank */
import React, {useEffect, useState} from 'react'
import {SidebarMenuItem} from './SidebarMenuItem'
import {getRoleWiseSidebar} from '../../../../../app/modules/apps/role-access/role-setting-list/core/_requests'
import {useAuth} from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  const [sidemenu, setsideMenu] = useState<any>([])
  const {currentUser} = useAuth()
  const rolesId = currentUser?.rolesId

  useEffect(() => {
    getRoleWiseSidebar(rolesId).then((res: any) => {
      const sidemenuItems = res.data.data
      const result = sidemenuItems.reduce(
        (acc: {[x: string]: any[]}, item: {module: {title: {name: any}}}) => {
          const titleName = item.module?.title.name
          if (!acc[titleName]) {
            acc[titleName] = []
          }
          acc[titleName].push(item)
          return acc
        },
        {}
      )
      console.log("result",result)
      const restructuredData = Object.keys(result).map((key) => ({
        [key.toLowerCase()]: result[key],
      }))
      setsideMenu(restructuredData)
    })
  }, [])
  if (currentUser?.employeeType === 1) {
    return (
      <>
        {sidemenu.map((item: any, i: any) => {
          const currentSubtitle = Object.keys(item)[0]

          return (
            <div key={i}>
              <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                  <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                    {currentSubtitle}
                  </span>
                </div>
              </div>
              {item[currentSubtitle].map((module: any, j: any) => (
                <SidebarMenuItem
                  key={j}
                  to={module.module?.link}
                  icon={module.module?.icon}
                  title={module.module?.name}
                  fontIcon='bi-app-indicator'
                />
              ))}
            </div>
          )
        })}
      </>
    )
  } else {
    return (
      <>
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Dashboard</span>
          </div>
        </div>
        <SidebarMenuItem
          to='/dashboard'
          icon='/media/icons/duotune/art/art002.svg'
          title='Dashboard'
          fontIcon='bi-layers'
        />
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>HR</span>
          </div>
        </div>
        <SidebarMenuItem
          to='/role'
          icon='/media/icons/duotune/general/gen048.svg'
          title='Role'
          fontIcon='bi-layers'
        />
        <SidebarMenuItem
          to='/employee'
          icon='/media/icons/duotune/general/gen049.svg'
          title='Employee'
          fontIcon='bi-layers'
        />
        <SidebarMenuItem
          to='/page-access'
          icon='/media/icons/duotune/general/gen051.svg'
          title='Role Access'
          fontIcon='bi-layers'
        />
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crematoriums</span>
          </div>
        </div>
        <SidebarMenuItem
          to='/vendors'
          icon='/media/icons/duotune/general/gen049.svg'
          title='Vendor'
          fontIcon='bi-layers'
        />
        <SidebarMenuItem
          to='/crematorium'
          icon='/media/icons/duotune/finance/fin001.svg'
          title='Crematorium'
          fontIcon='bi-layers'
        />
        <SidebarMenuItem
          to='/bookings'
          icon='/media/icons/duotune/general/gen014.svg'
          title='Bookings'
          fontIcon='bi-layers'
        />
        <SidebarMenuItem
          to='/payments'
          icon='/media/icons/duotune/finance/fin010.svg'
          title='Payments'
          fontIcon='bi-layers'
        />
        <SidebarMenuItem
          to='/burner-type'
          icon='/media/icons/duotune/general/gen017.svg'
          title='Burner Type'
          fontIcon='bi-layers'
        />
        <SidebarMenuItem
          to='/report'
          icon='/media/icons/duotune/general/gen017.svg'
          title='Burner'
          fontIcon='bi-layers'
        />
        <SidebarMenuItem
          to='/maintenance'
          icon='/media/icons/duotune/coding/cod009.svg'
          title='Maintenance'
          fontIcon='bi-layers'
        />
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Reports</span>
          </div>
        </div>
        <SidebarMenuItem
          to='/reports'
          icon='/media/icons/duotune/graphs/gra001.svg'
          title='Report'
          fontIcon='bi-layers'
        />
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Settings</span>
          </div>
        </div>
        <SidebarMenuItem
          to='/web-settings'
          icon='/media/icons/duotune/coding/cod001.svg'
          title='Web settings '
          fontIcon='bi-layers'
        />
      </>
    )
  }
}

export {SidebarMenuMain}
